import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BaseButton } from '@heidi-pay/heidi-component-library/components/Buttons/BaseButton';
import { FormControl } from '@material-ui/core';
import { Box, styled, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useTranslation } from '@hooks';
import { setAbandonVisible } from '@redux';
import { CopyBlock } from '../../../components';
import { SentrySeverity } from '../../../utils';
import { ContinueButtonPortal } from '../heylight/ContinueButton/ContinueButtonPortal';

const DEFAULT_REDIRECT_SECONDS = 8;

const StyledButton = styled(BaseButton, {
  shouldForwardProp: (prop): boolean => prop !== 'width',
})<{ width?: string }>(() => ({
  maxWidth: '327px',
  textTransform: 'uppercase',
}));

interface IMerchantRedirectBlockProps {
  name: string;
  options?: {
    redirectSeconds?: number;
  };
  value: string;
}

export const MerchantRedirectButton = ({
  name,
  options,
  value: url,
}: IMerchantRedirectBlockProps) => {
  const { t } = useTranslation();
  const { redirectSeconds = DEFAULT_REDIRECT_SECONDS } = options ?? {};

  const [countdown, setCountdown] = useState(0);
  const dispatch = useDispatch();

  const updateAbandonVisible = useCallback(
    (value: boolean) => dispatch(setAbandonVisible(value)),
    [dispatch],
  );

  const countdownIncrement = useMemo((): number => {
    if (redirectSeconds === 0) {
      return 100;
    }
    return 10 / redirectSeconds;
  }, [redirectSeconds]);

  useEffect(() => {
    const logToSentryAndRedirect = async (): Promise<void> => {
      try {
        Sentry.captureMessage(
          `User redirected to the merchant e-commerce platform. URL: ${url}`,
          SentrySeverity.info,
        );
        await Sentry.flush(1000);
      } finally {
        window.location.assign(url);
      }
    };

    if (countdown === 100) {
      setTimeout(() => {
        window.onbeforeunload = null;
        logToSentryAndRedirect();
      }, 500);
    }
  }, [countdown, url]);

  const updateCountdown = useCallback(() => {
    setCountdown(prevCountdown =>
      prevCountdown >= 100 ? 100 : prevCountdown + countdownIncrement,
    );
  }, [countdownIncrement]);

  useEffect(() => {
    updateAbandonVisible(false);
    const timer = setInterval(updateCountdown, 100);
    return () => {
      clearInterval(timer);
      updateAbandonVisible(true);
    };
  }, [countdownIncrement, updateAbandonVisible, updateCountdown]);

  return (
    <FormControl component="fieldset" fullWidth={true}>
      <ContinueButtonPortal>
        <Box alignItems="center" display="flex" flexDirection="column">
          <StyledButton
            color="primary"
            href={url}
            type="submit"
            variant="contained"
          >
            <Typography variant="button">
              {t(`fields.merchantRedirectButton.${name}.content`)}
            </Typography>
          </StyledButton>
          <Box>
            <CopyBlock
              i18nKey={`fields.merchantRedirectButton.${name}.label`}
              value={{ redirectSeconds }}
            />
          </Box>
        </Box>
      </ContinueButtonPortal>
    </FormControl>
  );
};
