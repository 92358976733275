import { lazy } from 'react';

// instore components
import {
  InstorePricingStructureCodePicker,
  InstoreProductSelectStandalone,
} from '@instore/components';

import {
  ChangeCardComplete,
  CodeVerification,
  ConsentScrollableContainer,
  ConsentScrollableZoomContainer,
  CountdownTimer,
  ExternalDocumentViewer,
  HtmlContainer,
  InstorePaymentSummaryMobilezone,
  IntroPagolightPro,
  IntrumIframeContainer,
  IntrumSigningHoldingContainer,
  OrderProfilePicker,
  PagolightHoldingContainer,
  PaymentMethodBlock,
  RadioButtonsStacked,
  RedirectComponent,
  ScrollableContainer,
  SecciLink,
  SecciLinkLegalNotice,
  SelectPickerItalianProvince,
  SelectPickerRaw,
  SelectPickerSearchable,
  SpidCallbackProcessor,
  SPIDWelcomeContainer,
  SuccessIcon,
  TermsAndConditionsButtonGroupPicker,
  TextBlockValue,
  FailureIcon,
  HelpTooltip,
  MerchantRedirectButton,
} from './components';
import CustomerAgreementPagolightPro from './components/CustomerAgreementPagolightProDeprecated';
import HiddenField from './components/HiddenField';
import HiddenMonthlyPaymentsField from './components/HiddenMonthlyPaymentsField';
import ImageRow from './components/ImageRow';
import InstoreMoneyInput from './components/InstoreMoneyInput';
import InstorePaymentSummary from './components/InstorePaymentSummary';
import LoginContainer from './components/LoginContainer';
import MandatoryActionContainer from './components/MandatoryActionContainer';
import MerchantRedirectBlock from './components/MerchantRedirectBlock';
import MonthlyPaymentsPicker from './components/MonthlyPaymentsPicker';
import OrderSummary from './components/OrderSummary';
import PaymentGatewayContainer from './components/PaymentGatewayContainer';
import PaymentMethodPicker from './components/PaymentMethodPicker';
import RadioButtons from './components/RadioButtons';
import Spacer from './components/Spacer';
import Spinner from './components/Spinner';
import TextBlock from './components/TextBlock';
import WaitingBot from './components/WaitingBot';

export default {
  ChangeCardComplete,
  CodeVerification,
  ConsentScrollableContainer,
  ConsentScrollableZoomContainer,
  CountdownTimer,
  CustomerAgreementPagolightPro,
  ExternalDocumentViewer,
  HelpTooltip,
  HiddenField,
  HiddenMonthlyPaymentsField,
  HtmlContainer,
  ImageRow,
  InstoreMerchantRedirectQR: lazy(() =>
    import('@instore/components/InstoreMerchantRedirectQR').then(module => ({
      default: module.InstoreMerchantRedirectQR,
    })),
  ),
  InstorePaymentSummary,
  InstorePaymentSummaryMobilezone,
  InstorePricingStructureCodePicker,
  InstoreProductSelectStandalone,
  IntroPagolightPro,
  IntrumIframeContainer,
  IntrumSigningHoldingContainer,
  LoginContainer,
  MerchantRedirectBlock,
  MerchantRedirectButton,
  MoneyInput: InstoreMoneyInput,
  MonthlyPaymentsPicker,
  MandatoryActionContainer,
  OnfidoItalianHealthCardWelcomeScreenContainer: lazy(() =>
    import('./components/OnfidoItalianHealthCardWelcomeScreenContainer'),
  ),
  OrderSummary,
  OrderProfilePicker,
  OnfidoContainerLatest: lazy(() =>
    import('./components/OnfidoContainerLatest'),
  ),
  OnfidoContainerV14: lazy(() =>
    import('./components/OnfidoContainerLatest/OnfidoContainerV14'),
  ),
  PagolightHoldingContainer,
  PaymentMethodBlock,
  PaymentGatewayContainer,
  PaymentMethodPicker,
  PDFDocumentViewer: lazy(() => import('./components/PDFDocumentViewer')),
  RadioButtons,
  RadioButtonsStacked,
  RedirectComponent,
  ScrollableContainer,
  SecciLink,
  SecciLinkLegalNotice,
  SelectPickerItalianProvince,
  SelectPickerRaw,
  SelectPickerSearchable,
  Spacer,
  SpidCallbackProcessor,
  SPIDWelcomeContainer,
  Spinner,
  SuccessIcon,
  FailureIcon,
  TermsAndConditionsButtonGroupPicker,
  TextBlock,
  TextBlockValue,
  WaitingBot,
};
